import createReducer from '../createReducer';
import { AVAILABLE_SPORTS } from './actions';

const initialState = {
  sports: []
};

export default createReducer(initialState, {
  [AVAILABLE_SPORTS]: (state, action) => ({
    ...state,
    sports: action.sports
  })
});
