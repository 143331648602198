import React, { Component, Suspense } from 'react';
import { IntlProvider } from 'react-intl';
import { connect } from 'react-redux';
import { BrowserRouter as Router, Redirect, Route, Switch } from 'react-router-dom';
import ColorSwitcher from './components/common/ColorSwitcher';
import NotificationContainer from './components/common/react-notifications/NotificationContainer';
import { isMultiColorActive } from './constants/defaultValues';
import { getDirection } from './helpers/Utils';
import useToken from './helpers/useToken';

import AppLocale from './lang';
// import Login from './views/user/login';
// import Register from './views/user/register';

const ViewApp = React.lazy(() => import(/* webpackChunkName: "views-app" */ './views/app'));
const ViewUser = React.lazy(() => import(/* webpackChunkName: "views-user" */ './views/user'));
const ViewError = React.lazy(() => import(/* webpackChunkName: "views-error" */ './views/error'));
const ViewPublic = React.lazy(() =>
  import(/* webpackChunkName: "views-public" */ './views/public')
);

const AuthenticatedApp = props => {
  const { token } = useToken();

  if (!token) {
    return <Redirect to={{ pathname: '/user/login', state: { from: props.location } }} />;
  }

  return <ViewApp {...props} />;
};

class App extends Component {
  constructor(props) {
    super(props);
    const direction = getDirection();
    if (direction.isRtl) {
      document.body.classList.add('rtl');
      document.body.classList.remove('ltr');
    } else {
      document.body.classList.add('ltr');
      document.body.classList.remove('rtl');
    }
  }

  render() {
    const { locale } = this.props;
    const currentAppLocale = AppLocale[locale];

    return (
      <div>
        <IntlProvider locale={currentAppLocale.locale} messages={currentAppLocale.messages}>
          <React.Fragment>
            <NotificationContainer />
            {isMultiColorActive && <ColorSwitcher />}
            <Suspense fallback={<div className='loading' />}>
              <Router>
                <Switch>
                  <Redirect exact path='/' to='/app' />
                  <Route path='/app' render={props => <AuthenticatedApp {...props} />} />
                  <Route path='/user' render={props => <ViewUser {...props} />} />
                  <Route path='/error' exact render={props => <ViewError {...props} />} />
                  <Route path='/public' exact render={props => <ViewPublic {...props} />} />
                  <Redirect to='/error' />
                </Switch>
              </Router>
            </Suspense>
          </React.Fragment>
        </IntlProvider>
      </div>
    );
  }
}

const mapStateToProps = ({ settings, auth: { user } }) => {
  const { locale } = settings;
  return { locale, profile: user };
};
const mapActionsToProps = {};

export default connect(mapStateToProps, mapActionsToProps)(App);
