import createReducer from '../createReducer';
import {
  SELECTED_MATCHES,
  IS_SINGLE_BET,
  SELECTED_ODDS,
  IS_OPEN_MOBILE_BET_SLIP
} from './actions';
const initialState = {
  matches: [],
  odds: [],
  isSingleBet: true,
  isOpen: false
};

export default createReducer(initialState, {
  [SELECTED_MATCHES]: (state, action) => ({
    ...state,
    matches: action.matches
  }),
  [IS_SINGLE_BET]: (state, action) => ({
    ...state,
    isSingleBet: action.isSingleBet
  }),
  [SELECTED_ODDS]: (state, action) => ({
    ...state,
    odds: action.odds
  }),
  [IS_OPEN_MOBILE_BET_SLIP]: (state, action) => ({
    ...state,
    isOpen: action.isOpen
  })
});
