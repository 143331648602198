const USER_INFO = 'user/USER_INFO';
const CURRENT_BETTING_ACCOUNT_ID = 'user/CURRENT_BETTING_ACCOUNT_ID';

const setUserInfo = info => ({ type: USER_INFO, userInfo: info });
const setCurrentBettingAccountId = bettingAccountId => ({
  type: CURRENT_BETTING_ACCOUNT_ID,
  currentBettingAccountId: bettingAccountId
});

export { USER_INFO, CURRENT_BETTING_ACCOUNT_ID, setUserInfo, setCurrentBettingAccountId };
