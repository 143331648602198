import { api } from '../api';

export const users = {
  state: [],
  reducers: {
    update(state, payload) {
      return payload;
    },
    updateOne(state, payload) {
      const i = state.findIndex(item => item.id === payload.id);
      const newState = state.slice();
      return newState.splice(i, 1, { ...state[i], ...payload });
    },
    append(state, payload) {
      return [...state, payload];
    }
  },
  effects: dispatch => ({
    async getAll(payload, rootState) {
      const users = await api.get('/users');
      dispatch.users.update(users.data);
    },
    async getById(payload, rootState) {
      const user = await api.get('/users/' + payload.id);
      dispatch.users.append(user.data);
    },
    async updateCurrent(payload, rootState) {
      const user = await api.put('/users/me', {
        ...payload
      });
      return dispatch.users.updateOne(user.data);
    }
  }),
  selectors: {
    isSingleUser() {
      return (rootState, props) =>
        rootState.auth.user.companyId === null || !rootState.auth.user.Company.active;
    }
  }
};
