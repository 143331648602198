// import { Auth } from 'aws-amplify';
import axios from 'axios';
// import { API_URL } from '../constants/defaultValues';

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL,
  headers: {
    'Content-Type': 'application/json'
  }
});

api.interceptors.request.use(async config => {
  try {
    const tokenString = localStorage.getItem('token');
    const userToken = JSON.parse(tokenString);

    if (userToken) {
      config.headers.common['Authorization'] = 'Bearer ' + userToken;
    }
  } catch (err) {
    console.error(err);
  }
  return Promise.resolve(config);
});

api.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    if (error && error.response && error.response.status === 401) {
      return window.location.replace('/user/login');
    } else if (error && error.response && error.response.status === 422) {
      return Promise.reject(error.response.data);
    } else {
      return Promise.reject(error);
    }
  }
);

export { api };
