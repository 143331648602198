const SELECTED_MATCHES = 'matches/SELECTED_MATCHES';
const SELECTED_ODDS = 'matches/SELECTED_ODDS';
const IS_SINGLE_BET = 'matches/IS_SINGLE_BET';
const IS_OPEN_MOBILE_BET_SLIP = 'matches/IS_OPEN_MOBILE_BET_SLIP';

const setSelectedMatchesList = list => ({ type: SELECTED_MATCHES, matches: list });
const setIsSingleBet = enable => ({ type: IS_SINGLE_BET, isSingleBet: enable });
const setSeletedOdds = list => ({ type: SELECTED_ODDS, odds: list });
const setIsOpenMobileBetSlip = enable => ({
  type: IS_OPEN_MOBILE_BET_SLIP,
  isOpen: enable
});

export {
  SELECTED_MATCHES,
  IS_SINGLE_BET,
  SELECTED_ODDS,
  IS_OPEN_MOBILE_BET_SLIP,
  setSelectedMatchesList,
  setIsSingleBet,
  setSeletedOdds,
  setIsOpenMobileBetSlip
};
