import createReducer from '../createReducer';
import { BETS } from './actions';
const initialState = {
  bets: []
};

export default createReducer(initialState, {
  [BETS]: (state, action) => ({
    ...state,
    bets: action.bets
  })
});
