import createReducer from '../createReducer';

import { ACCOUNT } from './actions';

const initialState = {
  account: {}
};

export default createReducer(initialState, {
  [ACCOUNT]: (state, action) => ({
    ...state,
    account: action.account
  })
});
