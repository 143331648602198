import createReducer from '../createReducer';
import { TRANSACTIONS } from './actions';

const initialState = {
  transactions: []
};

export default createReducer(initialState, {
  [TRANSACTIONS]: (state, action) => ({
    ...state,
    transactions: action.transactions
  })
});
