import createReducer from '../createReducer';
import { GAMES } from './actions';
const initialState = {
  games: []
};

export default createReducer(initialState, {
  [GAMES]: (state, action) => ({
    ...state,
    games: action.games
  })
});
